<script setup>
import Header from './components/SddsHeader';
import Footer from './components/SddsFooter';
import { inject, onMounted, computed, watch } from 'vue';
import { useMainStore } from './store';
import { useRouter } from 'vue-router';
import PageSpinner from './components/PageSpinner';
import StickySupportButton from './components/StickySupportButton.vue';
import { addCustomTrackingEvent } from './utils/customTracking';
import SddsToastWrapper from './components/SddsToastWrapper';
import { datadogRum } from '@datadog/browser-rum';


const keycloak = inject('keycloak');
const store = useMainStore();
const router = useRouter();
const market = computed(() => store.market);


onMounted(() => {
  const adobeLaunchScriptUrl = process.env.VUE_APP_DS_ADOBE_LAUNCH_SCRIPT_URL;
  if (adobeLaunchScriptUrl) {
    let tag = document.head.querySelector(`[src="${adobeLaunchScriptUrl}"`);
    if (!tag) {
      tag = document.createElement("script");
      tag.setAttribute("src", adobeLaunchScriptUrl);
      tag.setAttribute("async", "");
      document.head.appendChild(tag);
    }
    window.adobeDataLayer = window.adobeDataLayer || [];
  }
  addCustomTrackingEvent('loadApp');
});

const updateToken = () => {
  keycloak.updateToken(90).then(() => {
    store.token = keycloak.token;
    store.isAdmin = keycloak.hasResourceRole('ds-admin');
    store.isSuperUser = keycloak.hasResourceRole('ds-superuser');
    store.isSalesman = keycloak.hasResourceRole('ds-salesman');
    store.externalStaffReference = keycloak.idTokenParsed.customer?.external_staff_reference;
    store.federationUserAccountId = keycloak.idTokenParsed.sub;
    return true;
  }).catch(() => {
    // handle this in some way
    return false;
  });
};

// Token lives for 5 minutes, refresh token lives for 1 hour, session lasts for 10 hours.
// Update token every 4 minutes to avoid any potential issues
const updateTokenIntervalHandle = setInterval(updateToken, 240000);

watch(market, async () => {
  datadogRum?.setGlobalContextProperty('market', market.value);
});
</script>
<template>
  <sdds-theme />
  <template v-if="store.appLoading">
    <PageSpinner />
  </template>
  <template v-if="!store.appLoading">
    <Header
      :show-language-selection="store.isAdmin || store.isSuperUser"
    />
    <main class="main-container__bottom-padding">
      <router-view />
      <StickySupportButton />
      <div class="sdds-toast-wrapper">
        <SddsToastWrapper />
      </div>
    </main>
    <Footer />
  </template>
</template>

<style scoped>
.main-container__bottom-padding {
  padding-bottom: var(--sdds-spacing-element-48);
}
</style>

<style>
p,
ul {
  font-size: 1.2em;
  line-height: 1.8em;
}

a {
  color: var(--sdds-blue);
  font-weight: 500;
  letter-spacing: 0.02em;
  text-decoration: none;
  border-bottom: 2px solid;
}

em {
  font-style: inherit;
  color: v-bind('store.activeMarket?.highlightColor');
}

.flex-center {
  display: flex;
  place-content: center;
}

.sdds-toast-wrapper {
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;
    z-index: 20;
}
/* SDDS  */

:root,
html {
  --sdds-modal-bg: var(--sdds-grey-50) !important;

  --sdds-btn-primary-background: var(--sdds-blue);
  --sdds-btn-primary-color-hover: var(--sdds-white) !important;
  --sdds-btn-primary-border-color-hover: var(--sdds-blue-600) !important;
  --sdds-btn-primary-background-disabled: var(--sdds-grey-100) !important;
  --sdds-btn-primary-border-color-disabled: var(--sdds-blue) !important;
  --sdds-btn-primary-color-disabled: var(--sdds-blue) !important;
}

.sdds-btn {
  font-weight: 600;
}

sdds-modal>div[slot="sdds-modal-body"] {
  max-height: 70vh;
  overflow-x: auto;
}


sdds-modal>h3[slot="sdds-modal-headline"] {
  margin: 0;
}

sdds-modal>button[slot="sdds-modal-actions"] {
  margin-top: var(--sdds-spacing-layout-16);
}

.sdds-modal-overflow {
  padding-right: 16px;
}

.year-end-offer-price {
  color: #DA2B40;
  font-weight: bold;
}

.margin-top-16 {
  margin-top: var(--sdds-spacing-layout-16);
}

.margin-top-24 {
  margin-top: var(--sdds-spacing-layout-24);
}

.margin-top-32 {
  margin-top: var(--sdds-spacing-layout-32);
}

.margin-top-48 {
  margin-top: var(--sdds-spacing-layout-48);
}

.margin-top-64 {
  margin-top: var(--sdds-spacing-layout-64);
}

.margin-top-96 {
  margin-top: var(--sdds-spacing-layout-96);
}

.margin-bottom-16 {
  margin-bottom: var(--sdds-spacing-layout-16);
}

.margin-bottom-24 {
  margin-bottom: var(--sdds-spacing-layout-24);
}

.margin-bottom-32 {
  margin-bottom: var(--sdds-spacing-layout-32);
}

.margin-bottom-48 {
  margin-bottom: var(--sdds-spacing-layout-48);
}

.margin-bottom-64 {
  margin-bottom: var(--sdds-spacing-layout-64);
}

.margin-bottom-96 {
  margin-bottom: var(--sdds-spacing-layout-96);
}

.padding-top-16 {
  padding-top: var(--sdds-spacing-layout-16);
}

.padding-top-24 {
  padding-top: var(--sdds-spacing-layout-24);
}

.padding-top-32 {
  padding-top: var(--sdds-spacing-layout-32);
}

.padding-top-48 {
  padding-top: var(--sdds-spacing-layout-48);
}

.padding-top-64 {
  padding-top: var(--sdds-spacing-layout-64);
}

.padding-top-96 {
  padding-top: var(--sdds-spacing-layout-96);
}

.padding-left-16 {
  padding-left: var(--sdds-spacing-layout-16);
}

.padding-left-24 {
  padding-left: var(--sdds-spacing-layout-24);
}

.padding-left-32 {
  padding-left: var(--sdds-spacing-layout-32);
}

.padding-left-48 {
  padding-left: var(--sdds-spacing-layout-48);
}

.padding-left-64 {
  padding-left: var(--sdds-spacing-layout-64);
}

.padding-left-96 {
  padding-left: var(--sdds-spacing-layout-96);
}

.el-margin-left-8 {
  margin-left: var(--sdds-spacing-element-8);
}

.el-margin-left-16 {
  margin-left: var(--sdds-spacing-element-16);
}

.el-margin-right-8 {
  margin-right: var(--sdds-spacing-element-8);
}

.el-margin-right-16 {
  margin-right: var(--sdds-spacing-element-16);
}

.el-margin-top-8 {
  margin-top: var(--sdds-spacing-element-8);
}

.el-margin-top-16 {
  margin-top: var(--sdds-spacing-element-16);
}

.el-margin-bottom-8 {
  margin-bottom: var(--sdds-spacing-element-8);
}

.el-margin-bottom-16 {
  margin-bottom: var(--sdds-spacing-element-16);
}
</style>
