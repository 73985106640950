<script setup>
import { ref, computed } from 'vue';
import Slider from '@vueform/slider';

import '../styles/configurator-selection.css';

const emit = defineEmits(['select', 'update:modelValue']);
const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Object,
    default: () => {
      return null;
    },
  },
  showTooltip: {
    type: Boolean,
    default: true,
  },
  valueProperty: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    required: true,
  },
});

const sliderValue = ref(props.modelValue.id.at(-1));

const sliderMax = computed(() => {
  return props.options.length;
});

const valueProp = computed(() => {
  return props.valueProperty || 'value';
});

const formatSlideTooltip = (sliderValue) => {
  return props.options.find(x => x.id === `${props.type}-${sliderValue}`)[valueProp.value];
};

const handleSliderChange = (sliderValue) => {
  const selectObject = {
    type: props.type,
    option: props.options.find(x => x.id === `${props.type}-${sliderValue}`),
  };
  emit('select', selectObject);
  emit('update:modelValue', selectObject.option);
};

</script>

<template>
  <Slider
    v-model="sliderValue"
    :min="1"
    :max="sliderMax"
    :tooltips="props.showTooltip"
    :format="formatSlideTooltip"
    :lazy="false"
    @update="handleSliderChange"
  />
</template>

<style scoped>
  @import '@vueform/slider/themes/default.css';
</style>
