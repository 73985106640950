<script setup>
  import { computed, onBeforeMount, inject } from 'vue';
  import SimpleSlider from './SimpleSlider';
  import { updateSelections } from '../services/digitalSalesService';
  import { useMainStore } from '../store/index.js';

  const store = useMainStore();

  const props = defineProps({
    items: {
      type: Object,
      default: null,
    },
  });

  const adobeEvent = inject('adobeEvent');

  let debounceTimer = null;
  const debounceSelect = (selectObject) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      adobeEvent({
        event: 'leasingOptionsSelectionChange',
        leasingSelection: {
          label: 'CS & DS contract length',
          id: selectObject.option.id,
          value: selectObject.option.value,
        },
      }),
      updateSelections(store.selected).then(res => {
      }).catch(err => {
        console.error('update selections error: ', err);
      });
    }, 1500);
  };

  const contractLengthData = computed(() => {
    return store.options.leasing.find(o => o.id === 'serviceContractLength');
  });

  onBeforeMount(() => {
    if (!store.selected.leasing.serviceContractLength) store.selected.leasing.serviceContractLength = {
      ...store.options.leasing.find(o => o.id === 'serviceContractLength').options.find(o => o.default),
    };
  });

</script>

<template>
  <div class="service-contract-selection sdds-row sdds-u-flex">
    <div class="sdds-col-lg-12 sdds-col-xs-12">
      <div class="flex-container">
        <h5 class="flex-grow">
          {{ $i18n('serviceContractLength') }}
        </h5>
      </div>
      <div class="flex-container">
        <div class="item-description">
          {{ $i18n(store.selected.leasing.serviceContractLength.description) }}
        </div>
        <div class="flex-grow">
          <SimpleSlider
            v-if="contractLengthData"
            v-model="store.selected.leasing.serviceContractLength"
            :type="contractLengthData.id"
            :options="contractLengthData.options"
            :show-tooltip="false"
            value-property="description"
            @select="debounceSelect"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .service-contract-selection .flex-container {
    display: flex;
    align-items: center;
  }
  .service-contract-selection .flex-container > div {
    margin-right: var(--sdds-spacing-element-24);
  }
  .service-contract-selection .flex-grow {
    flex-grow: 1;
  }
</style>
