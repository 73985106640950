import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import { useMainStore } from './store/index';
import SummaryView from './views/SummaryView';
import ConfiguratorView from './views/ConfiguratorView';
import HomeView from './views/HomeView';
import ConfirmationView from './views/ConfirmationView';
import ActiveOffersView from './views/ActiveOffersView';
import PageNotFoundView from './views/PageNotFoundView.vue';
import { getUserMetadata, checkFeatureToggle } from './services/digitalSalesService';

const requiredRole = process.env.VUE_APP_DS_KEYCLOAK_REQUIRED_ROLE;
const requiredSalesmanRole = process.env.VUE_APP_DS_KEYCLOAK_REQUIRED_SALESMAN_ROLE;

const isAuthorized = (app) => {
  return app.$keycloak.authenticated && (app.$keycloak.hasResourceRole(requiredRole) || app.$keycloak.hasResourceRole(requiredSalesmanRole));
};

const isMarketSupported = (market) => {
  return ['pl', 'de'].includes(market); // TODO: Add supported markets to store
};

const loadInitialData = async () => {
  const store = useMainStore();
  try {
    store.locale = store.getDefaultLocale;
    const initialLoad = await Promise.all([
      store.loadTranslations(),
      getUserMetadata(store.federationUserAccountId).catch(err => {
        console.warn('failed to get locale. using fallback instead');
        return null;
      }),
      checkFeatureToggle('webapp_yearEndOfferBanner').catch(err => console.warn('error fetching feature toggle :: ', err)),
      checkFeatureToggle('webapp_year2024OfferBanner').catch(err => console.warn('error fetching feature toggle :: ', err)),
      checkFeatureToggle('webapp_show_ecolutionBlob').catch(err => console.warn('error fetching feature toggle :: ', err)),
    ]);

    const metadataResponse = initialLoad[1];
    store.featureToggleData[`${store.market}#webapp_yearEndOfferBanner`] = initialLoad[2]?.data;
    store.featureToggleData[`${store.market}#webapp_year2024OfferBanner`] = initialLoad[3]?.data;
    store.featureToggleData[`${store.market}#webapp_show_ecolutionBlob`] = initialLoad[4]?.data;

    store.fallbackLocale = metadataResponse?.data?.locale || store.getDefaultLocale;

    if (metadataResponse?.data?.locale && store.locale !== metadataResponse?.data?.locale) {
      store.locale = metadataResponse.data.locale;
      await store.loadTranslations();
    }
  } catch (error) {
    console.error('loadInitialData', error);
  }
};

const handleOfferAccess = async (app, to) => {
  const store = useMainStore();
  store.appLoading = true;
  const isNewOffer = store.offerId !== to.query.id;
  if(isNewOffer) await loadInitialData();
  const id = to.query.id;
  if(!id || !isAuthorized(app)) {
    return ({ name: 'Home', params: { market: store.market }, query: { id: id } });
  }
  store.setOfferId(id);
  if(isNewOffer || !store.options) await store.loadConfiguration();
  if(store.offerLoadDataError){
    store.appLoading = false;
    return ({ name: 'Home', params: { market: store.market } });
  }
  if (store.offerStatus.finalized && to.name !== 'ConfirmationView') {
    return ({ name: 'ConfirmationView', params: { market: store.market }, query: { id: store.offerId } });
  }
  if (!store.offerStatus.finalized && (store.offerStatus.expired || store.offerStatus.isOfferNotValid || !store.offerStatus.regulationsApprovedForUser)) {
    store.appLoading = false;
    return { name: 'Home', params: { market: store.market }, query: { id: store.offerId } };
  }
  store.appLoading = false;
};

const createRouter = (app) => {
  const router = createVueRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    },
    routes: [
      {
        path: '/:market([a-z]{2})/',
        name: 'Home',
        component: HomeView,
        beforeEnter: async (to, from) => {
          const store = useMainStore();
          store.market = to.params.market;
          if (!isMarketSupported(store.market)) {
            return { name: 'MarketNotSupported', params: { pathMatch: to.path.split('/').slice(1) }, query: to.query, hash: to.hash };
          }
          if (!isAuthorized(app)) {
            store.appLoading = false;
            return true;
          }
          if(store.offerLoadDataError){
            store.appLoading = false;
            return true;
          }
          if (!to.query.id) {
            return {
              name: 'ActiveOffersView',
              params: { market: store.market },
            };
          }
          if(store.offerId !== to.query.id) {
            store.setOfferId(to.query.id);
            await loadInitialData();
            await store.loadConfiguration();
          }
          store.appLoading = false;
        },
      },
      {
        path: '/:market([a-z]{2})/active',
        name: 'ActiveOffersView',
        component: ActiveOffersView,
        beforeEnter: async (to, from) => {
          if(!isAuthorized(app)) {
            return { name: 'Home', params: { market: to.params.market } };
          }
          const store = useMainStore();
          store.market = to.params.market;
          if (!isMarketSupported(store.market)) {
            return { name: 'MarketNotSupported', params: { pathMatch: to.path.split('/').slice(1) }, query: to.query, hash: to.hash };
          }
          store.appLoading = true;
          if(store.options) store.resetOfferData();
          await loadInitialData();
          store.appLoading = false;
        },
      },
      {
        path: '/:market([a-z]{2})/configure',
        name: 'ConfiguratorView',
        component: ConfiguratorView,
        beforeEnter: (to, from) => {
          const store = useMainStore();
          store.market = to.params.market;
          if (!isMarketSupported(store.market)) {
            return { name: 'MarketNotSupported', params: { pathMatch: to.path.split('/').slice(1) }, query: to.query, hash: to.hash };
          }
          return handleOfferAccess(app, to);
        },
      },
      {
        path: '/:market([a-z]{2})/summary',
        name: 'SummaryView',
        component: SummaryView,
        beforeEnter: (to, from) => {
          const store = useMainStore();
          store.market = to.params.market;
          if (!isMarketSupported(store.market)) {
            return { name: 'MarketNotSupported', params: { pathMatch: to.path.split('/').slice(1) }, query: to.query, hash: to.hash };
          }
          return handleOfferAccess(app, to);
        },
      },
      {
        path: '/:market([a-z]{2})/confirmation',
        name: 'ConfirmationView',
        component: ConfirmationView,
        beforeEnter: (to, from) => {
          const store = useMainStore();
          store.market = to.params.market;
          if (!isMarketSupported(store.market)) {
            return { name: 'MarketNotSupported', params: { pathMatch: to.path.split('/').slice(1) }, query: to.query, hash: to.hash };
          }
          return handleOfferAccess(app, to);
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'Default',
        beforeEnter: async (to) => {
          const store = useMainStore();
          const userMetadata = await getUserMetadata(store.federationUserAccountId).catch(err => {
            console.warn('failed to get locale in default router');
            store.appLoading = false;
            return { name: 'PageNotFoundView', params: { pathMatch: to.path.split('/').slice(1) }, query: to.query, hash: to.hash };
          });
          store.market = String(userMetadata?.data?.locale || store.getDefaultLocale).substring(0,2);
          if (!isMarketSupported(store.market)) {
            return { name: 'MarketNotSupported', params: { pathMatch: to.path.split('/').slice(1) }, query: to.query, hash: to.hash };
          }
          return { name: 'Home', params: { market: store.market }, query: { id: to.query.id } };
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFoundView',
        component: PageNotFoundView,
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'MarketNotSupported',
        component: PageNotFoundView,
        beforeEnter: () => {
          const store = useMainStore();
          console.warn('Market not supported:', store.market);
          store.appLoading = false;
        },
      },
    ],
  });
  return router;
};

export {
  createRouter,
};
