<script setup>
import { computed } from 'vue';
import TickIcon from './TickIcon.vue';
import ClockIcon from './ClockIcon.vue';

const props = defineProps({
  name: {
    type: String,
    default: 'truck',
  },
});

const iconLink = computed(() => `https://cdn.digitaldesign.scania.com/icons/website_hosted/${props.name}.svg`);

</script>

<template>
  <TickIcon
    v-if="name === 'tick'"
    class="sdds-icon-custom"
  />
  <ClockIcon
    v-else-if="name === 'clock'"
    class="sdds-icon-custom"
  />
  <object
    v-else
    :data="iconLink"
    type="image/svg+xml"
  />
</template>

<style scoped>
object, .sdds-icon-custom {
  height: 6rem;
  width: 6rem;
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  margin-right: var(--sdds-spacing-element-4);
}
</style>
