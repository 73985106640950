<script setup>
import { ref } from 'vue';
import SummaryItem from './SummaryItem';
import SpecificationModal from './SpecificationModal';


defineProps({
  selections: {
    type: Object,
    default() {
      return {};
    },
  },
});

const specModalId = 'summaryPage-specModal';
const specSearchText = ref('');

const handleSearchInput = (input) => {
  specSearchText.value = input;
};

const handleSpecModalClick = () => {
  specSearchText.value = '';
};

</script>

<template>
  <div>
    <div class="sdds-container summary-card">
      <div class="sdds-row">
        <div class="sdds-col-lg-12">
          <h2>
            {{ $i18n('yourSelections') }}
          </h2>
          <p>{{ $i18n('generalTruckInformationSummary') }}</p>
        </div>
      </div>
      <div class="sdds-row">
        <div
          v-for="(item, key) in selections"
          :key="key"
          class="sdds-col-lg-6 sdds-col-xs-12 summary-item-margin"
        >
          <summary-item :item="{ id: key, data: item }" />
        </div>
      </div>
      <div class="sdds-row specification-modal-link">
        <div>
          <a
            :id="specModalId"
            href="#"
            data-dd-action-name="view specification"
            @click.prevent="handleSpecModalClick()"
          >{{ $i18n('viewSpecificationLinkText') }}</a> <font-awesome-icon icon="magnifying-glass" />
        </div>
      </div>
    </div>
    <teleport to="body">
      <SpecificationModal
        :id="specModalId"
        :search-text="specSearchText"
        @search="handleSearchInput"
      />
    </teleport>
  </div>
</template>

<style scoped>
.summary-item-margin {
    margin-bottom: var(--sdds-spacing-layout-16);
}
.specification-modal-link {
  margin-top: var(--sdds-spacing-layout-48);
  justify-content: center;
  font-size: medium;
}
.summary-card {
  border: 1px solid var(--sdds-grey-300);
  border-radius: 1rem;
  padding: var(--sdds-spacing-layout-16) var(--sdds-spacing-layout-24);
  margin-right: 20px;
  background-color: var(--sdds-white);
}

@media only screen and (max-width: 480px) {
  .summary-card{
    min-width: 85vw;
  }
}
</style>
