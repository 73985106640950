function formatIsoStringToLocalDateTime(isoString, locale = 'en-GB') {
  const options = {
      dateStyle: 'short',
      timeStyle: 'short',
  };

  const formatter = new Intl.DateTimeFormat(locale, options);
  return formatter.format(new Date(isoString));
}

export { formatIsoStringToLocalDateTime };

