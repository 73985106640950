<script setup>
</script>

<template>
  <section>
    <h1>Page Not Found</h1>
    <p>Please review your bookmark to ensure it is valid</p>
  </section>
</template>

<style scoped>
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
  }
  @media only screen and (max-width: 500px) {
    section {
      padding: 1em;
    }
  }
</style>
