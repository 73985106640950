<script setup>
import { computed, onMounted, inject, onBeforeMount } from 'vue';
import HeroSection from '../components/HeroSection';
import ConfigurationHeader from '../components/ConfigurationHeader';
import ConfigurationSidebar from '../components/ConfigurationSidebar';
import ConfigurationSliderSelection from '../components/ConfigurationSliderSelection';
import TemplateSelector from './template/TemplateSelector.vue';
import { useConfiguratorApi } from '../composables/configurator';
import { useItemIntersection } from '../composables/itemIntersection';
import { useMainStore } from '../store/index.js';
import { useRouter } from 'vue-router';
import PageSpinner from '../components/PageSpinner';
import { updateSelections } from '../services/digitalSalesService';
import { addCustomTrackingEvent } from '../utils/customTracking';

const adobeEvent = inject('adobeEvent');
const i18n = inject('i18n');
const store = useMainStore();
const router = useRouter();
const { totalFormattedPrice, totalPrice } = useConfiguratorApi();
const { observe, intersectedId } = useItemIntersection(adobeEvent);

defineProps({
  title: {
    type: String,
    default: '',
  },
});

onMounted(() => {
  observe(store.sections.map(section => section.id));
  adobeEvent({
    event: 'pageView',
    page: {
      pageName: 'ConfiguratorView',
      offerId: store.offerId,
    },
  });
  addCustomTrackingEvent('configuratorView');
});

onBeforeMount(() => {
  setFeatureToggle();
});

const setFeatureToggle = async () => {
  store.showYearEndOfferBanner = false;
  store.showYear2024OfferBanner = false;
  if (store.misc?.specType && store.misc?.specType === 'stock_vehicle' && !store.misc?.frameAgreement) {
    const response = store.featureToggleData[`${store.market}#webapp_yearEndOfferBanner`];
    if (response) {
      const { isActive, isEffectiveUser, isFeatureDateValid } = response;
      store.showYearEndOfferBanner = isActive && isEffectiveUser && isFeatureDateValid;
    }
    const response2 = store.featureToggleData[`${store.market}#webapp_year2024OfferBanner`];
    if (response2) store.showYear2024OfferBanner = response2.isActive;
  }
};

const options = computed(() => {
  return store.options;
});

const images = computed(() => {
  return store.images;
});

const isLoading = computed(() => {
  return !store.options;
});

const scrollToId = (id) => {
  const element = document.getElementById(id);
  element.scrollIntoView({ behavior: 'smooth' });
};

let debounceTimer = null;
const debounceSelect = (object, section) => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(() => {
    handleOptionSelect(object, section);
  }, 1500);
};

const handleOptionSelect = (object, section) => {
  updateSelections(store.selected).then(res => {
  }).catch(err => {
    console.error('update selections error: ', err);
    return;
  });
  if(section === 'leasing') return;
  adobeEvent({
    event: 'selectionChange',
    truckConfig: {
      section: section,
      fpc_code: object.option.fpcCode,
      type: object.type,
      value: i18n(object.option.value, [], 'en-GB'),
    },
  });
  const newSection = { ...store.selected[section], [object.type]: object.option };
  store.updateImages({ ...store.selected, [section]: newSection });
};
const handleHeroAction = (event) => {
  if (event.includes('proceed')) goToSummary();
  if (event.includes('configure')) scrollToId('powertrain');
};
const goToSummary = () => {
  router.push({ name: 'SummaryView', params: { market: store.market }, query: { id: store.offerId } });
};

const optionsLeasing = computed(() => {
  return store.options.leasing.filter(o => o.id !== 'serviceContractLength');
});

const offerTemplate = computed(() => {
  const template = store.misc?.template?.trim()?.toUpperCase();
  return template ?? `${store.market.toUpperCase()}_INDEX`;
});

const specType = computed(() => {
  return store.misc?.specType?.trim()?.toLowerCase();
});

const isFrameAgreement = computed(() => {
  return store.misc?.frameAgreement || false;
});

const goToActive = () => {
  router.push({ name: 'ActiveOffersView', params: { market: store.market } });
};

</script>

<template>
  <template v-if="isLoading">
    <PageSpinner />
  </template>
  <template v-if="!isLoading">
    <ConfigurationHeader
      title="Test"
      @scroll-to-section="scrollToId"
    />
    <HeroSection
      :images="images"
      :price="totalFormattedPrice.price"
      :price-suffix="totalFormattedPrice.priceSuffix"
      @click="handleHeroAction"
    />
    <div class="configurator">
      <aside class="configurator__sidebar">
        <ConfigurationSidebar
          :leasing-options="optionsLeasing"
          @scroll-to-section="scrollToId"
          @select="debounceSelect($event, 'leasing')"
        />
      </aside>
      <div class="configurator__content">
        <template v-if="store.selectedPaymentType === 'leasing'">
          <section
            id="leasing-options"
            class="configurator-section configurator__content--leasing-options"
          >
            <h2>{{ $i18n('leasingOptionsText') }}</h2>
            <div class="configurator-section__row">
              <template
                v-for="item in optionsLeasing"
                :key="item.id"
              >
                <ConfigurationSliderSelection
                  v-if="item"
                  v-model="store.selected.leasing[item.id]"
                  :title="item.title"
                  :type="item.id"
                  :options="item.options"
                  :show-slider-extreme-values="true"
                  :show-tooltip="false"
                  :show-tooltip-value="item.id === 'downPayment'"
                  :tooltip-value="item.id === 'downPayment' ? `${totalPrice.downPaymentPrice.toLocaleString('en').replace(/,/g,' ')} ${$i18n(totalPrice.priceSuffixOneTime)}` : null"
                  @select="debounceSelect($event, 'leasing')"
                />
              </template>
            </div>
          </section>
        </template>
        <TemplateSelector
          :offer-template="offerTemplate"
          :spec-type="specType"
          :is-frame-agreement="isFrameAgreement"
          @select="handleOptionSelect"
        />
        <div class="view-summary-wrapper flex-center sdds-u-relative">
          <span class="go-back-available-offers">
            <svg
              class="el-margin-right-8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              transform="matrix(6.123233995736766e-17,1,-1,6.123233995736766e-17,0,0)"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.046 5.685a1 1 0 0 0-1.414-1.415l-9.9 9.9a2.6 2.6 0 0 0 0 3.678l9.9 9.9a1 1 0 1 0 1.415-1.415L4.722 17.01h24.306a1 1 0 0 0 0-2H4.722l9.325-9.324Z"
                fill="currentColor"
              />
            </svg>
            <a
              href="#"
              data-analytics="{'eventComponent':'bottom','eventType':'link','eventLabel':'go to top'}"
              data-dd-action-name="go to top"
              @click="scrollToId('app')"
            >{{ $i18n('goToTop') }}
            </a>
            <br><br>
            <svg
              class="el-margin-right-8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.046 5.685a1 1 0 0 0-1.414-1.415l-9.9 9.9a2.6 2.6 0 0 0 0 3.678l9.9 9.9a1 1 0 1 0 1.415-1.415L4.722 17.01h24.306a1 1 0 0 0 0-2H4.722l9.325-9.324Z"
                fill="currentColor"
              />
            </svg>
            <a
              data-analytics="{'eventComponent':'bottom','eventType':'link','eventLabel':'go back to available offers'}"
              data-dd-action-name="go back to available offers"
              @click="goToActive"
            >{{ $i18n('goBackToAvailableOffers') }}
            </a>
          </span>
          <button
            data-analytics="{'eventComponent':'bottom','eventType':'button','eventLabel':'go to summary'}"
            type="button"
            class="sdds-btn sdds-btn-primary"
            data-dd-action-name="go to summary"
            @click="goToSummary"
          >
            {{ $i18n('viewSummary') }}
          </button>
        </div>
      </div>
    </div>
  </template>
</template>

<style scoped>
  .configurator {
    display: flex;
    place-content: center;
    padding: var(--sdds-spacing-layout-16);
  }
  .configurator__sidebar {
    flex: 0 0 250px;
    order: 2;
    will-change: min-height;
  }
  .configurator__content {
    flex: 1 1;
    margin: 0;
    order: 1;
  }

  .configurator__content--leasing-options {
    display: none;
  }

  .configurator__content--leasing-options > .configurator-section__row {
    gap: 10em;
  }

  .view-summary-wrapper {
    margin-top: calc(var(--sdds-spacing-layout-96) + var(--sdds-spacing-layout-16));
  }

  span.go-back-available-offers {
    position: absolute;
    bottom: calc(var(--sdds-spacing-layout-64) + var(--sdds-spacing-layout-16));
    left: var(--sdds-spacing-layout-32);
  }

  span.go-back-available-offers > svg {
    width: 5rem;
    vertical-align: bottom;
  }

  @media only screen and (max-width: 1294px) {
    .configurator__sidebar {
      display: none;
    }
    .configurator__content--leasing-options {
      display: flex;
    }
  }
  @media only screen and (max-width: 1000px) {
    .configurator__content--leasing-options > .configurator-section__row {
      flex-direction: column;
      gap: 3em;
    }
    span.go-back-available-offers {
      bottom: calc(var(--sdds-spacing-layout-64) + var(--sdds-spacing-layout-24));
    }
  }
</style>

<style>
  .configuration-header {
    display: none;
  }
  @media only screen and (max-width: 1294px) {
    .configuration-header {
      display: flex;
    }
  }
</style>
