<script setup>
  import { ref, computed } from 'vue';
  import { useMainStore } from '../store/index';
  import { useConfiguratorApi } from '../composables/configurator';
  import SpecificationModal from './SpecificationModal';
  import InformationModal from './InformationModal';
  import PriceSuffix from './PriceSuffix';
  import PriceTooltip from './PriceTooltip.vue';
  import MonthlyPriceTooltip from './MonthlyPriceTooltip';
  import { useRouter } from 'vue-router';

  /* eslint-disable vue/no-deprecated-slot-attribute */ // Slot attribute used by SDDS
  /* eslint-disable vue/no-v-html */
  defineEmits(['click']);
  const { totalFormattedPrice, totalPrice, repairMaintenanceBasePrice } = useConfiguratorApi();
  const store = useMainStore();
  const router = useRouter();

  const props = defineProps({
    images: {
      type: Object,
      default: () => {
        return {};
      },
    },
    price: {
      type: String,
      required: true,
    },
    priceSuffix: {
      type: String,
      required: true,
    },
  });

  store.loadSpecification();

  const specSearchText = ref('');
  const specModalId = 'heroSection-specModal';
  const operationalLeaseModalId = 'heroSection-operationalLeaseModal';
  const paymentTypes = store.options.payment ? store.options.payment.find(o => o.id === 'paymentType').options : [];

  const changePaymentType = (paymentType) => {
    store.selectedPaymentType = paymentType;
    store.selected.payment.paymentType = paymentTypes.find(p => p.value === paymentType);
  };

  const handleSearchInput = (input) => {
    specSearchText.value = input;
  };

  const handleSpecModalClick = () => {
    specSearchText.value = '';
  };

  const yearEndOfferPrice = computed(() => {
    if (store.showYearEndOfferBanner) {
      const calculatedPrice = (store.selectedPaymentType === 'leasing' ? totalPrice.value.price : totalPrice.value.monthlyPrice) - repairMaintenanceBasePrice.value + 1;
      return calculatedPrice.toLocaleString('en').replace(/,/g," ");
    } else return totalFormattedPrice.value.price;
  });

  const leasingYears = computed(() => {
    const contractLengths = store.options?.leasing?.find(x => x.id === 'contractLength')?.options;
    const min = contractLengths[0].value.split(' ')[0];
    const max = contractLengths[contractLengths.length-1].value.split(' ')[0];
    return [min/12, max/12];
  });

  const offerValidDate = computed(() => {
    if (!store.misc?.offerValidUntilDate) return ['N/A'];
    const date = new Date(store.misc.offerValidUntilDate);
    return [`${String(date.getDate()).padStart(2, '0')}.${(String(date.getMonth()+1)).padStart(2, '0')}.${String(date.getFullYear()).substring(2)}`];
  });


  const goToActive = () => {
    router.push({ name: 'ActiveOffersView', params: { market: store.market } });
  };

  const heroImage = computed(() => {
    return props.images[store.activeMarket?.defaultHeroImageType];
  });
</script>

<template>
  <div class="hero">
    <div class="hero__content">
      <div class="hero__image">
        <span
          class="go-back-available-offers"
        >
          <svg
            class="el-margin-right-8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.046 5.685a1 1 0 0 0-1.414-1.415l-9.9 9.9a2.6 2.6 0 0 0 0 3.678l9.9 9.9a1 1 0 1 0 1.415-1.415L4.722 17.01h24.306a1 1 0 0 0 0-2H4.722l9.325-9.324Z"
              fill="currentColor"
            />
          </svg>
          <a
            data-analytics="{'eventComponent':'hero','eventType':'link','eventLabel':'go back to available offers'}"
            data-dd-action-name="go back to available offers"
            @click="goToActive"
          >{{ $i18n('goBackToAvailableOffers') }}
          </a>
        </span>
        <img
          :src="heroImage"
          alt="Truck"
        >
        <div class="image-description">
          <a
            :id="specModalId"
            data-analytics="{'eventComponent':'hero','eventType':'modal','eventLabel':'view specification'}"
            href="#"
            data-dd-action-name="view specification"
            @click.prevent="handleSpecModalClick()"
          >{{ $i18n('viewSpecificationLinkText') }}</a> <font-awesome-icon icon="magnifying-glass" />
        </div>
      </div>
      <div class="hero__value-prop">
        <div :class="{ 'hero__value-prop-content-div-responsive': paymentTypes.length > 1 || store.selectedPaymentType === 'cash' }">
          <h1 v-html="store.selectedPaymentType === 'cash' ? $i18n('heroHeaderCash') : $i18n('heroHeader')" />
          <p class="sdds-paragraph-01">
            {{ store.selectedPaymentType === 'cash' ? $i18n('heroBodyCash') : $i18n('heroBody') }}
          </p>
          <ul class="sdds-body-01 hero-bullet-container-fixed-height">
            <template v-if="store.selectedPaymentType === 'leasing'">
              <li>{{ $i18n('heroBulletOne', leasingYears) }}</li>
            </template>
            <li>{{ $i18n('heroBulletTwo') }}</li>
            <li>{{ $i18n('heroBulletFour') }}</li>
            <template v-if="store.market === 'pl'">
              <li>{{ $i18n('heroBulletFive') }}</li>
            </template>
          </ul>
          <p class="sdds-body-01">
            {{ $i18n('heroOfferValidPeriod', offerValidDate) }}
            <template v-if="store.creationReferenceData?.companyName">
              {{ $i18n('heroOfferForYourCompany') }}<br>
              <strong>{{ store.creationReferenceData.companyName }}</strong>
            </template>
          </p>
          <template v-if="paymentTypes.length">
            <div 
              class="hero-section-payment-container"
              :class="{
                'hero-section-payment-container-only-leasing': paymentTypes.length === 1 && store.selectedPaymentType === 'leasing'
              }"
            >
              <div class="hero-section-paymentType-div">
                <div
                  v-for="paymentType in paymentTypes"
                  :key="paymentType.id"
                  class="hero-section-paymentType-option-div"
                >
                  <a
                    :data-analytics="`{'eventComponent':'sidebar','eventType':'button','eventLabel':'change payment type - ${paymentType.value}'}`"
                    class="sdds-navigation-tabs--tab"
                    :class="{
                      'sdds-navigation-tabs--tab__active': paymentType.value === store.selectedPaymentType,
                      'sdds-navigation-tabs--tab__unique': paymentTypes.length === 1,
                      'sdds-navigation-tabs--tab__inactive': paymentType.value !== store.selectedPaymentType,
                    }"
                    :data-dd-action-name="`change payment type - ${paymentType.value}`"
                    @click="changePaymentType(paymentType.value)"
                  >{{ $i18n(paymentType.description || paymentType.value) }}</a>
                </div>
              </div>
              <div class="hero-section-sdds-divider-light sdds-divider-light sdds-u-mt0" />
              <div
                class="hero-section-payment-info"
                :class="{ 'hero-section-payment-info-leasing-padding': paymentTypes.length > 1 && store.selectedPaymentType === 'leasing' }"
              >
                <template v-if="store.selectedPaymentType === 'leasing'">
                  <span class="sdds-u-m-auto">
                    <template v-if="store.showYearEndOfferBanner">
                      <span class="year-end-offer-price">{{ yearEndOfferPrice }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffix" /></span>
                      <span class="el-margin-left-8">
                        <PriceTooltip
                          placement="bottom"
                          selector-id="price-tooltip-hero-section"
                        />
                      </span>
                    </template>
                    <template v-else>
                      <span>{{ totalFormattedPrice.price }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffix" /></span>
                      <span class="el-margin-left-8">
                        <PriceTooltip
                          placement="bottom"
                          selector-id="price-tooltip-hero-section"
                        />
                      </span>
                    </template>
                  </span>
                </template>
                <template v-if="store.selectedPaymentType === 'cash'">
                  <div class="price-wrapper">
                    <div class="price-content">
                      <span class="sdds-u-block sdds-detail-02">{{ $i18n('purchasePrice') }}</span>
                      <div>
                        <span class="payment-amount">{{ totalFormattedPrice.price }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffix" /></span>
                      </div>
                    </div>
                  </div>
                  <span class="sdds-divider-dark-vertical" />
                  <div class="price-wrapper">
                    <div class="price-content">
                      <span class="sdds-u-block sdds-detail-02">{{ $i18n('monthlyPrice') }}</span>
                      <div>
                        <template v-if="store.showYearEndOfferBanner">
                          <span class="payment-amount year-end-offer-price">{{ yearEndOfferPrice }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffixMonthly" /></span>
                          <span class="el-margin-left-8"><MonthlyPriceTooltip selector-id="monthly-price-tooltip-hero-section" /></span>
                        </template>
                        <template v-else>
                          <span class="payment-amount">{{ totalFormattedPrice.monthlyPrice }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffixMonthly" /></span>
                          <span class="el-margin-left-8"><MonthlyPriceTooltip selector-id="monthly-price-tooltip-hero-section" /></span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <button
            type="button"
            class="sdds-btn sdds-btn-primary"
            data-analytics="{'eventComponent':'hero','eventType':'button','eventLabel':'go to summary'}"
            data-dd-action-name="go to summary"
            @click="$emit('click', 'proceed')"
          >
            {{ store.selectedPaymentType === 'cash' ? $i18n('buyTruckButtonText') : $i18n('leaseTruckButtonText') }}
          </button>
        </div>
        <div class="operational-text">
          <template v-if="store.selectedPaymentType === 'leasing'">
            <p class="foot-note sdds-detail-05">
              {{ $i18n('operationalLeaseHeroText') }}
              <a
                :id="operationalLeaseModalId"
                data-analytics="{'eventComponent':'hero','eventType':'modal','eventLabel':'view terms and conditions - leasing'}"
                href="#"
                data-dd-action-name="view terms and conditions - leasing"
              >{{ $i18n('operationalLeaseSeeMoreLinkText') }}</a>
            </p>
          </template>
          <template v-if="store.selectedPaymentType === 'cash'">
            <p class="foot-note sdds-detail-05">
              {{ $i18n('operationalCashHeroText') }}
              <a
                :id="operationalLeaseModalId"
                data-analytics="{'eventComponent':'hero','eventType':'modal','eventLabel':'view terms and conditions - cash'}"
                href="#"
                data-dd-action-name="view terms and conditions - cash"
              >{{ $i18n('operationalCashSeeMoreLinkText') }}</a>
            </p>
          </template>
        </div>
      </div>
    </div>
    <div class="hero__navigation">
      <button
        class="hero__navigation-button"
        data-analytics="{'eventComponent':'hero','eventType':'button','eventLabel':'fine tune this truck'}"
        data-dd-action-name="fine tune this truck"
        @click="$emit('click', 'configure')"
      >
        <div class="hero__navigation-button-icon">
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.273 9.783a1 1 0 0 1 1.415 0l9.888 9.888a.6.6 0 0 0 .848 0l9.888-9.888a1 1 0 1 1 1.415 1.414l-9.889 9.889a2.6 2.6 0 0 1-3.677 0l-9.888-9.889a1 1 0 0 1 0-1.414Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <span>{{ $i18n('fineTuneText') }}</span>
      </button>
    </div>
  </div>
  <teleport to="body">
    <SpecificationModal
      :id="specModalId"
      :search-text="specSearchText"
      @search="handleSearchInput"
    />
    <InformationModal
      v-if="store.selectedPaymentType === 'leasing'"
      :id="operationalLeaseModalId"
      title="operationalLeaseModalTitle"
      information-text="operationalLeaseModalText"
    />
    <InformationModal
      v-if="store.selectedPaymentType === 'cash'"
      :id="operationalLeaseModalId"
      title="operationalCashModalTitle"
      information-text="operationalCashModalText"
    />
  </teleport>
</template>

<style scoped>
  .hero {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: var(--sdds-grey-100);
    position: relative;
  }

  .hero__content {
    display: flex;
    place-content: center;
    flex-wrap: wrap-reverse;
    flex-grow: 3;
  }
    .hero__image {
      flex: 0.5 1 632px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 15rem;
      justify-content: center;
    }
      .hero__image > img {
        object-fit: cover;
        max-width: 100%;
      }
      .hero__image > .image-description {
        padding-top: 2rem;
      }

    .hero__value-prop {
      flex: 0.5 1 632px;
      display: grid;
      place-items: center;
      padding: var(--sdds-spacing-layout-16);
    }
      .hero__value-prop ul {
        list-style-type: none;
        padding-inline-start: 0px;
        margin-bottom: var(--sdds-spacing-layout-24);
      }
        .hero__value-prop li {
          margin-bottom: var(--sdds-spacing-layout-8);
        }
        .hero__value-prop li:before {
          content: '\2713';
          margin-right: var(--sdds-spacing-layout-16);
          font-weight: 600;
          color: v-bind('store.activeMarket?.highlightColor');
        }

  .hero__navigation {
    display: grid;
    place-items: center;
    margin-bottom: 1.2em;
    color: var(--sdds-grey-846);
  }
    .hero__navigation-button {
      background: none;
      border: none;
      cursor: pointer;
    }
    .hero__navigation-button svg {
      width: 3em;
      margin-bottom: -0.5em;
    }
    .hero__navigation-button-icon {
      -webkit-animation: pulse 5s infinite ease-in-out;
      -o-animation: pulse 5s infinite ease-in-out;
      -ms-animation: pulse 5s infinite ease-in-out; 
      -moz-animation: pulse 5s infinite ease-in-out; 
      animation: pulse 5s infinite ease-in-out;
    }

    @-webkit-keyframes pulse {
        0% { opacity: 0.3; }
        50% { opacity: 0.6; }
        100% { opacity: 0.3; }
    }

    @keyframes pulse {
        0% { opacity: 0.3; }
        50% { opacity: 0.6; }
        100% { opacity: 0.3; }
    }

  .foot-note {
    color: var(--sdds-grey-700);
    margin: var(--sdds-spacing-element-16) 0;
  }

  button svg {
    margin-right: var(--sdds-spacing-element-8);
  }
  .hero-section-payment-container {
    display: flex;
    flex-direction: column;
    width: 380px;
    height: 115px;
  }
  .hero-section-paymentType-div {
    display: inline-flex;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 4px;
  }
  .hero-section-paymentType-div .hero-section-paymentType-option-div {
    display: inline-flex;
    width: 50%;
  }
  .hero-section-paymentType-div .hero-section-paymentType-option-div a.sdds-navigation-tabs--tab {
    padding: 7px 0px;
    flex: auto;
    cursor: pointer;
    text-align: center;
  }
  .hero-section-paymentType-div .hero-section-paymentType-option-div a.sdds-navigation-tabs--tab::after {
    z-index: 2;
  }
  .hero-section-paymentType-div .hero-section-paymentType-option-div a.sdds-navigation-tabs--tab.sdds-navigation-tabs--tab__inactive {
    border: none;
  }
  .hero-section-payment-container .hero-section-payment-info {
    font-size: 2em;
    white-space: nowrap;
    display: flex;
    padding: 10px 0;
  }
  .hero-section-payment-container .hero-section-payment-info .payment-amount {
    font-size: 1em;
  }
  .priceSuffix__container {
    font-size: 0.4em;
    line-height: 1em;
  }
  .hero-section-payment-container .hero-section-payment-info.hero-section-payment-info-leasing-padding {
    padding: 20px 0;
  }
  .sdds-divider-dark-vertical {
    height: 40px;
    width: 2px;
    display: inline-flex;
  }
  .price-wrapper {
    width: calc(50% - 1px);
    display: inline-flex;
  }
  .price-content {
    margin: 0 auto;
  }
  .operational-text {
    justify-self: flex-start;
  }

  .hero-section-paymentType-div .hero-section-paymentType-option-div a.sdds-navigation-tabs--tab__unique::after {
    background-color: transparent;
  }

  .hero-section-payment-container-only-leasing {
    width: 170px;
  }

  .hero-section-payment-container-only-leasing .hero-section-paymentType-div .hero-section-paymentType-option-div {
    width: 100%;
  }

  span.go-back-available-offers {
    position: absolute;
    top: var(--sdds-spacing-layout-16);
    left: var(--sdds-spacing-layout-32);
    cursor: pointer;
  }

  span.go-back-available-offers > svg {
    width: 5rem;
    vertical-align: bottom;
  }

  @media only screen and (min-width: 1295px) {
    .hero-bullet-container-fixed-height {
      min-height: 152px;
      max-height: 152px;
      height: 152px;
    }
    .operational-text {
      height: 64px;
    }
  }

  @media only screen and (max-width: 1294px) {
    .hero {
      height: 100%;
      padding-bottom: var(--sdds-spacing-layout-24);
    }
    span.go-back-available-offers {
      top: var(--sdds-spacing-layout-8);
    }
    .hero__image {
      padding-top: unset;
    }
    .hero__image img {
      width: 60%;
    }
    .hero__navigation {
      display: none;
    }
    .hero-section-payment-container {
      display: none;
    }
  }

  @media only screen and (max-width: 720px) {
    .image-description {
      margin-top: -10px;
    }
    span.go-back-available-offers {
      left: var(--sdds-spacing-layout-16);
    }
  }
  @media only screen and (max-width: 530px) {
    .hero-section-payment-container .hero-section-payment-info .payment-amount {
      font-size: 0.8em;
    }
  }
  @media only screen and (max-width: 500px) {
    .hero__value-prop-content-div-responsive {
      padding-top: 2em;
    }
  }
  @media only screen and (max-width: 410px) {
    .hero-section-payment-container .hero-section-payment-info .payment-amount {
      font-size: 0.6em;
    }
    .hero-section-payment-container .hero-section-payment-info .sdds-detail-02 {
      font-size: calc(((14/ 4)) * 0.9rem);
    }
    .hero-section-payment-container .hero-section-payment-info .priceSuffix__container {
      font-size: 0.36em;
    }
  }
</style>
