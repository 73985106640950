<script setup>
import { onMounted, inject, computed } from 'vue';
import { useConfiguratorApi } from '../composables/configurator';
import { useItemIntersection } from '../composables/itemIntersection';
import { useRouter } from 'vue-router';
import { useMainStore } from '../store/index.js';
import StickySidebar from 'sticky-sidebar-v2/dist/sticky-sidebar';
import ConfigurationSliderSelection from '../components/ConfigurationSliderSelection';
import PriceSuffix from './PriceSuffix';
import PriceTooltip from './PriceTooltip';
import CashDepositPriceTooltip from './CashDepositPriceTooltip';
import MonthlyPriceTooltip from './MonthlyPriceTooltip';
import PriceBreakdownTooltip from './PriceBreakdownTooltip';

const emit = defineEmits(['select', 'scrollToSection']);

const props = defineProps({
  leasingOptions: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

const { totalFormattedPrice, totalPrice, repairMaintenanceBasePrice } = useConfiguratorApi();
const adobeEvent = inject('adobeEvent');
const { intersectedId } = useItemIntersection(adobeEvent);
const router = useRouter();
const store = useMainStore();

const sections = computed(() => store.sections);
const paymentTypes = store.options.payment ? store.options.payment.find(o => o.id === 'paymentType').options : [];

const yearEndOfferPrice = computed(() => {
  if (store.showYearEndOfferBanner) {
    const calculatedPrice = (store.selectedPaymentType === 'leasing' ? totalPrice.value.price : totalPrice.value.monthlyPrice) - repairMaintenanceBasePrice.value + 1;
    return calculatedPrice.toLocaleString('en').replace(/,/g," ");
  } else return totalFormattedPrice.value.price;
});

onMounted(() => {
  const sidebar = new StickySidebar('.configurator__sidebar', {
    containerSelector: '.configurator',
    innerWrapperSelector: '.configuration-sidebar',
  });
});

const handleSliderChange = (selectObject) => {
  adobeEvent({
    event: 'leasingOptionsSelectionChange',
    leasingSelection: {
      label: selectObject.type,
      id: selectObject.option.id,
      value: selectObject.option.value,
    },
  }),
  emit('select', selectObject);
};

const goToSummary = () => {
  router.push({ name: 'SummaryView', params: { market: store.market }, query: { id: store.offerId } });
};

const changePaymentType = (paymentType) => {
  store.selectedPaymentType = paymentType;
  store.selected.payment.paymentType = paymentTypes.find(p => p.value === paymentType);
};

</script>

<template>
  <div class="configuration-sidebar configuration-sidebar--sticky-sidebar-no-collapse">
    <ol>
      <li
        v-for="section in sections"
        :key="section.id"
        :class="{ active: section.id === intersectedId.value }"
      >
        <a
          :href="`#${section.id}`"
          :data-dd-action-name="`scroll to section - ${section.id}`"
          @click.prevent="emit('scrollToSection', section.id)"
        >{{ $i18n(section.name) }}</a>
      </li>
    </ol>
    <div
      class="sdds-divider-light"
      :class="{ 'sdds-u-mb0': paymentTypes.length > 1 }"
    />
    <div
      v-if="paymentTypes.length > 1"
      class="paymentType-div nav sdds-container-push-fluid sdds-u-p0"
    >
      <sdds-navigation-tabs class="hydrated">
        <a
          v-for="paymentType in paymentTypes"
          :key="paymentType.id"
          :data-analytics="`{'eventComponent':'sidebar','eventType':'button','eventLabel':'change payment type - ${paymentType.value}'}`"
          class="sdds-navigation-tabs--tab"
          :class="{
            'sdds-navigation-tabs--tab__active': paymentType.value === store.selectedPaymentType,
            'sdds-navigation-tabs--tab__inactive': paymentType.value !== store.selectedPaymentType,
          }"
          :data-dd-action-name="`change payment type - ${paymentType.value}`"
          @click="changePaymentType(paymentType.value)"
        >{{ $i18n(paymentType.description || paymentType.value) }}</a>
      </sdds-navigation-tabs>
    </div>
    <div class="price sdds-u-textalign-center">
      <template v-if="store.selectedPaymentType === 'leasing'">
        <span class="sdds-u-textalign-start">
          <template v-if="store.showYearEndOfferBanner">
            <span class="year-end-offer-price">{{ yearEndOfferPrice }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffix" /></span>
            <span class="el-margin-left-8"><PriceTooltip selector-id="price-tooltip-sidebar" /></span>
            <PriceBreakdownTooltip v-if="store.isSuperUser" />
            <br><span style="font-size: large;">{{ totalFormattedPrice.price }}</span>&nbsp;<span style="font-size: small;">{{ $i18n('yearEndOfferPriceHelperText1') }}</span>
            <br><span style="font-size: x-small;">{{ $i18n('yearEndOfferPriceHelperText2') }}</span>
          </template>
          <template v-else>
            <span>{{ totalFormattedPrice.price }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffix" /></span>
            <span class="el-margin-left-8"><PriceTooltip selector-id="price-tooltip-sidebar" /></span>
            <PriceBreakdownTooltip v-if="store.isSuperUser" />
          </template>
        </span>
      </template>
      <template v-if="store.selectedPaymentType === 'cash'">
        <div class="sdds-u-inline-block sdds-u-textalign-start">
          <span class="sdds-detail-02">{{ $i18n('purchasePrice') }}</span>
          <br>
          <span>{{ totalFormattedPrice.price }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffixOneTime" /></span>
          <span class="el-margin-left-8"><CashDepositPriceTooltip selector-id="cash-deposit-price-tooltip-sidebar" /></span>
          <br>
          <span class="sdds-detail-02">{{ $i18n('monthlyPrice') }}</span>
          <br>
          <template v-if="store.showYearEndOfferBanner">
            <span class="year-end-offer-price">{{ yearEndOfferPrice }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffix" /></span>
            <span class="el-margin-left-8"><MonthlyPriceTooltip selector-id="monthly-price-tooltip-sidebar" /></span>
            <PriceBreakdownTooltip v-if="store.isSuperUser" />
            <br><span style="font-size: large;">{{ totalFormattedPrice.monthlyPrice }}</span>&nbsp;<span style="font-size: small;">{{ $i18n('yearEndOfferPriceHelperText1') }}</span>
            <br><span style="font-size: x-small;">{{ $i18n('yearEndOfferPriceHelperText2') }}</span>
          </template>
          <template v-else>
            <span>{{ totalFormattedPrice.monthlyPrice }}</span>&nbsp;<span><PriceSuffix :price-suffix="totalFormattedPrice.priceSuffixMonthly" /></span>
            <span class="el-margin-left-8"><MonthlyPriceTooltip selector-id="monthly-price-tooltip-sidebar" /></span>
          </template>
        </div>
      </template>
    </div>
    <button
      data-analytics="{'eventComponent':'sidebar','eventType':'button','eventLabel':'go to summary'}"
      type="button"
      class="sdds-btn sdds-btn-primary sdds-btn-sm"
      data-dd-action-name="go to summary"
      @click="goToSummary()"
    >
      {{ $i18n('viewSummary') }}
    </button>
    <template v-if="store.selectedPaymentType === 'leasing'">
      <template
        v-for="item in leasingOptions"
        :key="item.id"
      >
        <ConfigurationSliderSelection
          v-if="item"
          v-model="store.selected.leasing[item.id]"
          :title="item.title"
          :type="item.id"
          :options="item.options"
          :show-tooltip="false"
          :show-tooltip-value="item.id === 'downPayment'"
          :tooltip-value="item.id === 'downPayment' ? `${totalPrice.downPaymentPrice.toLocaleString('en').replace(/,/g,' ')} ${$i18n(totalPrice.priceSuffixOneTime)}` : null"
          :show-in-sidebar="true"
          @select="handleSliderChange"
        />
      </template>
    </template>
  </div>
</template>

<style scoped>
  .configuration-sidebar {
    padding: var(--sdds-spacing-element-16);
    transform: translate(0, 0);
    transform: translate3d(0, 0, 0);
    background-color: var(--sdds-white);
    border-radius: 2px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 2.6px;
    height: 100vh;
    will-change: position, transform;
    overflow-y: auto;
  }
    .configuration-sidebar--sticky-sidebar-no-collapse {
      width: 250px !important;
    }

  .price {
    font-size: 2.0em;
    margin: 0.7em 0;
  }

  .configuration-sidebar > ol {
    list-style-type: none;
    padding-left: 0;
  }

  .configuration-sidebar > ol > li {
    margin-bottom: 4rem;
    font-family: "Scania Sans", Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 4rem;
    line-height: 6rem;
  }
  .configuration-sidebar > ol > li:before {
    content: '\f0020';
    width: 2px;
    height: 20px;
    border-radius: 2px;
    background-color: inherit;
    color: transparent;
    margin-right: var(--sdds-spacing-element-16);
    -webkit-transition: background-color 0.2s ease-out, font-weight 0.2s ease-out;
    -moz-transition: background-color 0.2s ease-out, font-weight 0.2s ease-out;
    -ms-transition: background-color 0.2s ease-out, font-weight 0.2s ease-out;
    -o-transition: background-color 0.2s ease-out, font-weight 0.2s ease-out;
    transition: background-color 0.2s ease-out, font-weight 0.2s ease-out;
  }

  .configuration-sidebar > ol > li:hover {
    font-weight: bold;
    cursor: pointer;
  }
  .configuration-sidebar > ol > li:hover:before {
    background-color: v-bind('store.activeMarket?.highlightColor');
  }
  .configuration-sidebar > ol > li.active:before {
    background-color: v-bind('store.activeMarket?.highlightColor');
  }

  .configuration-sidebar > ol > li > a {
    border: 0;
  }

  /* Full width button, with centered content, not supported by SDDS. At time of writing v4.5.0 */
  .configuration-sidebar > button {
    width: 100%;
    justify-content: center;
  }

  .configuration-sidebar > .configurator-selection {
    margin-top: var(--sdds-spacing-element-24);
  }

  .sdds-divider-light {
    margin: 1em 0;
  }
  .priceSuffix__container {
    font-size: 0.4em;
    line-height: 1em;
  }
  .information-icon {
    margin-left: 2rem;
  }
    .information-icon:hover { cursor: pointer; }

  .paymentType-div a.sdds-navigation-tabs--tab {
    flex: auto;
    cursor: pointer;
    text-align: center;
  }
  .paymentType-div a.sdds-navigation-tabs--tab.sdds-navigation-tabs--tab__inactive {
   border: none;
  }

  .price span.sdds-detail-02 {
    color: var(--sdds-grey-700);
  }

  @media only screen and (max-height: 860px) {
    .configuration-sidebar > .configurator-selection {
      margin-top: 0;
    }
    .configuration-sidebar > ol > li {
      margin-bottom: 2.8rem;
    }
  }
</style>
