<script setup>
  import { ref, computed } from 'vue';
  import { useMainStore } from '../store/index.js';
  import YearEndOfferBanner from './YearEndOfferBanner.vue';
  import Year2024OfferBanner from './Year2024OfferBanner.vue';
  import scaniaLogo from '../assets/scania-white.svg';
  import { useRouter } from 'vue-router';

  const props = defineProps({
    title: {
      type: String,
      default: '',
    },
    showLanguageSelection: {
      type: Boolean,
      default: false,
    },
  });

  const store = useMainStore();
  const router = useRouter();
  const isLanguageSelectionOpened = ref(false);
  const isCurrencySelectionOpened = ref(false);

  const selectedLanguage = computed(() => {
    return store.locale.substring(3, 5);
  });

  const selectedCurrency = computed(() => {
    return store.selectedPaymentCurrency;
  });

  const setLanguage = (locale) => {
    store.locale = locale;
    store.fpcCodeTranslations = [];
    store.loadTranslations();
    store.loadSpecificationTranslations();
    store.loadConfigurationTranslations();
  };

  const setCurrency = (currencyISOCode) => {
    store.selectedPaymentCurrency = currencyISOCode;
    store.selected.payment.paymentCurrency = store.currencies.find(p => p.id === currencyISOCode);
  };
</script>

<template>
  <header
    class="sdds-nav"
    :class="{ 'sdds-nav__avatar--opened': isLanguageSelectionOpened }"
  >
    <div class="sdds-nav__left">
      <div class="sdds-nav__app-wordmark">
        <img
          :src="scaniaLogo"
          alt="Scania"
        >
      </div>
      <div
        v-if="props.title"
        class="sdds-nav__app-name"
      >
        {{ $i18n(props.title) }}
      </div>
    </div>

    <div class="sdds-nav__right">
      <ul
        class="sdds-nav__toolbar-menu"
      >
        <li
          v-if="store.currencies.length > 1"
          class="sdds-nav__item sdds-nav__dropdown currency-selection"
          :class="{ 'sdds-nav__dropdown--opened': isCurrencySelectionOpened }"
        >
          <button
            class="sdds-nav__item-core"
            @click="isCurrencySelectionOpened = !isCurrencySelectionOpened"
          >
            {{ selectedCurrency }}
          </button>

          <ul class="sdds-nav__dropdown-menu">
            <li
              v-for="currency in store.currencies"
              :key="currency.id"
              class="sdds-nav__dropdown-item"
            >
              <a
                href="#"
                class="sdds-nav__dropdown-item-core"
                data-dd-action-name="set currency"
                @click="setCurrency(currency.id);isCurrencySelectionOpened=false"
              >
                {{ currency.id }}
              </a>
            </li>
          </ul>
        </li>
        <li
          v-if="showLanguageSelection"
          class="sdds-nav__item sdds-nav__avatar language-selection"
        >
          <button
            class="sdds-nav__avatar-btn"
            @click="isLanguageSelectionOpened = !isLanguageSelectionOpened"
          >
            {{ selectedLanguage }}
          </button>

          <ul class="sdds-nav__avatar-menu">
            <li
              v-for="language in store.getLanguages"
              :key="language.locale"
              class="sdds-nav__avatar-item"
            >
              <a
                href="#"
                class="sdds-nav__avatar-item-core"
                data-dd-action-name="set language"
                @click="setLanguage(language.locale);isLanguageSelectionOpened=false"
              >
                {{ language.description }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <a
        class="sdds-nav__item sdds-nav__app-logo"
        href="#"
        @click.prevent
      />
    </div>
  </header>
  <YearEndOfferBanner :show="store.showYearEndOfferBanner" />
  <Year2024OfferBanner :show="store.showYear2024OfferBanner" />
</template>

<style scoped>
  .sdds-nav--white {
    background-color: var(--sdds-white);
    border-bottom: 1px solid;
    border-color: var(--sdds-grey-200);
  }

    .sdds-nav__item { border-left: 1px solid; }
    .sdds-nav--white .sdds-nav__item { border-color: var(--sdds-grey-200); }
    .sdds-nav--white .sdds-nav__app-name { color: var(--sdds-blue); }
    .sdds-nav--white .sdds-nav__app-logo { background-color: var(--sdds-white); }
    .sdds-nav--white .sdds-nav__avatar-btn { background-color: var(--sdds-white); }
    .sdds-nav--white.sdds-nav__avatar-btn { color: var(--sdds-blue); }

  .sdds-nav__avatar-btn { color: var(--sdds-white); }
  .sdds-nav__avatar--opened .sdds-nav__avatar-btn { color: var(--sdds-blue); }
  .sdds-nav__app-wordmark {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 24px;
    white-space: nowrap;
  }
    .sdds-nav__app-wordmark img {
      height: 1.7em;
    }
  .sdds-nav__app-logo {
    border-right: 0;
    cursor: none;
  }
  .sdds-nav__app-logo:hover { background-color: inherit; }
  .sdds-nav__app-logo:focus {
    outline: inherit;
    outline-offset: inherit;
  }

  .sdds-nav__avatar-menu,
  .sdds-nav__dropdown-menu {
    z-index: 999;
  }


  @media only screen and (max-width: 1000px) {
    .sdds-nav__right .offer-selection,
    .sdds-nav__right .language-selection {
      display: none;
    }
  }
  @media only screen and (max-width: 992px) {
    .sdds-nav__right .currency-selection .sdds-nav__dropdown-menu {
      right: 66px;
      right: 0;
    }
  }
  @media only screen and (max-width: 360px) {
    /* .sdds-nav__right .language-selection .sdds-nav__avatar-menu .sdds-nav__avatar-item, */
    .sdds-nav__right .currency-selection .sdds-nav__dropdown-menu .sdds-nav__dropdown-item {
      width: 180px;
    }
  }
</style>
